.home {
  height: 100%;
}

.banner-container {
  max-width: 100%;
  height: auto;
  background-image: url("https://res.cloudinary.com/dhykfmali/image/upload/w_1300,q_80/consultanta_bgg51v.webp");
  background-size: cover;
  background-position: center;
}

.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-evenly;
  text-align: center;
  padding: 6rem 0;
  min-height: 60vh;
  gap: 5rem;
}
.title {
  font-size: 4rem;
  color: #f0d9a0;
  margin: 0;
}
.banner-title {
  font-size: 6rem;
}

.banner-text {
  font-size: 2rem; /* Micșorează font-ul pentru text pentru a se potrivi pe dispozitive mobile */
  max-width: 60%; /* Lățimea maximă pentru text */
  color: white;
  margin: auto;
  font-weight: 600;
}
.redirect-home {
  max-width: 25rem;
  color: white;
  font-size: 3.5rem;
  border: 2px solid #f0d9a0;
  padding: 1.5rem 3rem;
  text-align: center;
  font-weight: 600;
  border-radius: 0.9rem;
  transition: 0.5s;
}
.redirect-home:hover {
  background-color: white;
  color: #f0d9a0;
  border: 2px solid #f0d9a000;
}
@media (max-width: 768px) {
  .banner-title {
    font-size: 4rem;
  }

  .banner-text {
    font-size: 1.6rem;
    max-width: 90%;
    margin: auto;
  }

  .redirect-home {
    font-size: 3rem;
    padding: 1.5rem 1rem;
  }
}
