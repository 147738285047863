/* NotFound.css */

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.not-found-content {
  text-align: center;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 1rem;
}

.not-found-title {
  font-size: 2.5rem;
  color: #404040;
  margin: 0;
}

.not-found-text {
  font-size: 1.2rem;
  color: #404040;
  margin-top: 1rem;
}

.not-found-link {
  display: inline-block;
  font-size: 1.2rem;
  color: #f0d9a0;
  background-color: #404040;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  margin-top: 1rem;
  transition: background-color 0.3s, color 0.3s;
}

.not-found-link:hover {
  background-color: #f0d9a0;
  color: #404040;
}
