.spinner {
  border: 4.5rem solid #491553;
  ;
  border-left-color: #f0d9a0; /* Culoarea spinnerului */
  border-radius: 50%;
  width: 30rem;
  height: 30rem;
  animation: spin 1s linear infinite;
  margin: auto;
  margin-top: 5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
