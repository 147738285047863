.App {

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #f8f9f9;
}
main {
}
.redirect {
  font-size: 1.4rem;
  text-decoration: none;
}
