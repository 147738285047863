.aboutMeContainer {
  max-height: 130vh;
  margin-bottom: 3rem;
  text-align: center;
  background-color: white;
  padding: 2rem 1rem;

}

.quote-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  display: flex;
  gap: 1rem;
  max-width: 100rem;
  margin: auto;
}

.quote-image img {
  width: 30rem;
  height: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.quote-text {
  flex: 1;
  padding: 1rem;
  text-align: center;
}

.quote-text p {
  font-size: 2.2rem;
}

@media (max-width: 800px) {
  .aboutMeContainer {
    max-height: unset;
  }
  .quote-container {
    flex-direction: column; /* Schimbă direcția flex pentru a face imaginea și textul să fie unul sub altul */
    align-items: center; /* Afișează elementele pe axa orizontală în centrul containerului */
    gap: 1.5rem; /* Adaugă un spațiu mai mare între imagine și text */
    max-width: 90%; /* Lățime maximă pentru a se potrivi pe ecranul mic */
    margin: auto;
    padding: 1.5rem; /* Reduce padding-ul pentru a ocupa mai puțin spațiu vertical */
  }

  .quote-image img {
    max-width: 100%; /* Imaginea să ocupe întreaga lățime disponibilă */
    height: auto;
  }

  .quote-text {
    flex: none; /* Anulează flex-grow pentru a nu crește în înălțime */
    padding: 1rem 0; /* Reduce padding-ul pentru a ocupa mai puțin spațiu vertical */
  }

  .quote-text p {
    font-size: 1.8rem; /* Micșorează dimensiunea textului pentru a se potrivi pe ecranul mic */
  }
}

@media (max-height: 700px) {
  .quote-text p {
    font-size: 1.55rem; /* Micșorează dimensiunea textului pentru a se potrivi pe ecranul mic */
  }
}
