.promo-container {
  text-align: center;
  background: linear-gradient(#2b0b31, #491553);
  display: flex;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: space-evenly;
}
.left-promo {
  display: flex;
  color: white;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.promo-container h1 {
  font-size: 5rem;
  color: white;
}

.promo-container h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.promo-container ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 2rem;
}

.promo-container li {
  font-size: 1.5rem;
  color: #f0d9a0;
  font-weight: 600;
}

.redirect-promo {
  padding: 4rem 2rem;
  color: white;
  font-weight: 600;
  border: 2px solid #f0d9a0;
  border-radius: 0.9rem;
}

.redirect-promo:hover {
  background-color: white;
  color: #f0d9a0;
  border: 2px solid #f0d9a000;
}

@media (max-width: 768px) {
  .promo-container {
    flex-direction: column;
  }
}
