/* Style for the cookie consent message */
.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color:#5b1b68;
    color: #fff;
    padding: 10px;
    text-align: center;
    z-index: 100;
  }
  
  .cookie-consent p {
    margin: 0;
    font-size: 1.6rem;
  }
  
  .cookie-consent button {
    background-color: #f0d9a0;
    color: #333;
    border: none;
    padding: .5rem 1rem;
    cursor: pointer;
  }
  
  /* Style to disable content until cookies are accepted */
  .content-wrapper {
    opacity: 0.3;
    pointer-events: none;
  }
  
  /* Style to enable content after cookies are accepted */
  .content-wrapper.accepted {
    opacity: 1;
    pointer-events: auto;
  }
  