.steps-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem ;
  margin: auto;
  gap: 1rem;
background-color: white;
}

.steps-container p {
  font-size: 2rem;
}

.steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  
}
.steps-description {
  max-width: 60%;
  text-align: center;
}
.steps-title {
  text-align: center;
  display: flex;
  align-content: center;
  gap: 1rem;
  
}
.step-box {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  box-shadow: -1px 0px 7px #0000001c;
  max-width: 35rem;
}
.step-box h1 {
  margin: 0;
  font-size: 10rem;
  color: #f0d9a0;
  align-self: flex-start; /* Aici am modificat pentru aliniere sus */
}
.step-box-right h2{
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .steps {
    grid-template-columns: 1fr;

    grid-template-rows: 1fr 1fr 1fr;
  }
  .steps-description {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .steps-description {
    max-width: 100%;
    padding: 0.5rem;
  }
  .steps-title {
    display: inline-block;
  }
}

@media (max-width: 320px) {
  .step-box {
    flex-direction: column;
  }
}
