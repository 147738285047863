



.items{
    background: linear-gradient( #2b0b31 ,#491553 );
    text-align: center;
    padding: 1rem;

}
.ads-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    align-content: center;
    padding: 2rem 4rem;

}

.item-container {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem; /* Mărimea fontului redusă pentru conținutul principal */
    align-items: center;
    justify-content: center;
    color: #dfca96;
    padding: 1.5rem;
    align-self: start;
    margin-top: 3rem;
    gap: 1rem;
}

.item-container h1 {
    font-size: 2.2rem; /* Mărimea fontului mai mare pentru titluri */
}

.item-container p {
    font-size: 1.8rem; /* Mărimea fontului redusă pentru paragrafe */
    color: white;
}
  
.item-title{
    display: flex;
    align-items: center;
    gap: 1rem;
}
  
@media (max-width:768px) {

    .ads-container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        padding: 2rem; /* Redu padding-ul pentru dispozitivele mici */
    }

}
