.footer-container{
    background: linear-gradient( #491553,#2b0b31);

}
.upper-footer{
    display: grid;
    padding: 1rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
}

.contact-title{
    color: #f0d9a0;
    font-size: 2.5rem;
    text-align: left;
    grid-column: span 2;
    margin-bottom: 1rem;
   
}
.icon{
    color: #f0d9a0;
}

.footer-list-upper{
    list-style: none;
    font-size: 1.5rem;
    color: white;
}
.footer-list-upper li{
    display: flex;
    align-items: center;
    justify-items: baseline;
    gap: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
}
.map-container{
    position: relative;
    width: 100%;
 
}
#defaultMapId{
    width: 40rem;
}
.maps-footer{
    grid-column: span 2;
    justify-self: center;
}
.lower-footer{
    box-shadow: -4px 0px 1px white;
    padding: 1rem;

}

.credit{
    display: flex;
    font-size: 1.5rem;
    justify-content: space-evenly;
}

.credit p{
    color: white;
}
.credit a{
    color: white;
}
@media (max-width: 768px) {


    .upper-footer{
        display: flex;
        flex-direction: column;
        align-items: baseline;
        gap: 1rem;
    }
    .credit{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}


@media (max-width: 500px){

   
   
}



@media (max-width: 420px){

    #defaultMapId{
        width: 33rem;
    }
}
@media (max-width: 340px){

    #defaultMapId{
        width: 30rem;
    }
}
@media (max-width: 320px){

    #defaultMapId{
        width: 26rem;
    }
}