nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #491553;
  background: linear-gradient(#491553, #2b0b31, black);
}

.logo-nav-container {
  display: flex;
  align-items: center;
}

.logo-image {
  max-width: 13rem;
}
.barou-image {
  max-width: 8rem;
}

.redirect-nav-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.nav-redirect {
  color: white;
  transition: 0.3s;
  font-weight: 600;
}
.nav-redirect:hover {
  color: #f0d9a0;
  transform: translateY(5%);
}

/* Stiluri pentru ecranul mai mic de 768px (dispozitive mobile) */
@media (max-width: 460px) {

nav{
  flex-direction: column;
}
}
@media (max-width: 460px) {
  .logo-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .logo-image,
  .barou-image {
    max-width: 8rem;
    object-fit: contain;
  }

  /* .redirect-nav-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 100%;
  } */

  .nav-redirect {
    color: white;
    font-weight: 600;
    margin: 0.5rem;
  }
}
